import { AutoComplete, Form, Input, Select, Tag } from "antd";
import { useRuleStringRequired } from "app/utils/FormValidation";
import { useTranslation } from "react-i18next";
import Utils from "../../utils";
// import countries from "./countries+states+cities.json";
import { FormInstance } from "antd/lib/form/Form";
import { Address } from "app/models/Address";
import countryServices from "app/services/country.service";
import { useEffect, useState } from "react";
import AddressAutocomplete from "./AddressAutocomplete";
import { useSelector } from "react-redux";
import { langSelector } from "app/redux/slides/locale.slide";

const { Option } = Select;
interface DefaultProps {
  form: FormInstance<any>;
  data?: Address;
  name?: string;
  title?: any;
  required?: boolean;
  disabled?: boolean;
  callbackCountryChanged?: (value: any) => void;
  defaultCountry?: string;
  onChange?: () => void;
  onSelected?: () => void;
}

function AddressForm(props: DefaultProps) {
  const { t } = useTranslation();
  const {
    title = "",
    name = "",
    required = true,
    disabled = false,
    form,
    data,
    onChange,
    onSelected,
    callbackCountryChanged
  } = props;
  const ruleStringRequired = useRuleStringRequired(required);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState<any[]>([]);
  const [countryCode, setCountryCode] = useState(data?.countryCode);

  useEffect(() => {
    setCountryCode(form.getFieldValue([name, "countryCode"]));
  }, [])

  const handleInputChange = () => {
    if (onChange) {
      onChange();
    }
  };

  const onAddressSelected = (values: any) => {
    form.setFieldValue([name, "houseNumber"], values.houseNumber);
    form.setFieldValue([name, "streetName"], values.streetName);
    form.setFieldValue([name, "state"], values.state);
    form.setFieldValue([name, "city"], values.city ?? values.state);
    form.setFieldValue([name, "country"], values.country);
    form.setFieldValue([name, "countryCode"], values.countryCode);
    form.setFieldValue([name, "postCode"], values.postCode);
    setCountryCode(values.countryCode);
    if(onSelected) onSelected();
    if(callbackCountryChanged) callbackCountryChanged(values.countryCode);
  };

  return (
    <>
      {title && <p className="sub-l">{title}</p>}
      <div className="row" id={`${name}`}>
        <Form.Item name={[name, "id"]} hidden>
          <Input />
        </Form.Item>
        <Form.Item name={[name, "countryCode"]} hidden>
          <Input />
        </Form.Item>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("firstName")}
            name={[name, "firstName"]}
            rules={ruleStringRequired}
          >
            <Input
              maxLength={30}
              disabled={disabled}
              onChange={handleInputChange}
            />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("lastName")}
            name={[name, "lastName"]}
            rules={ruleStringRequired}
          >
            <Input
              maxLength={30}
              disabled={disabled}
              onChange={handleInputChange}
            />
          </Form.Item>
        </div>
        <>
          <div className="col col-12 col-md-6">
            <Form.Item
              label={t("email")}
              name={[name, "email"]}
              rules={[{ required: name == "billingAddress", type: "email" }]}
            >
              <Input
                maxLength={60}
                disabled={disabled}
                onChange={handleInputChange}
              />
            </Form.Item>
          </div>
          <div className="col col-12 col-md-6">
            <Form.Item label={t("phone")} name={[name, "phone"]}>
              <Input
                maxLength={15}
                disabled={disabled}
                onChange={handleInputChange}
              />
            </Form.Item>
          </div>
        </>
        {!disabled && <div className="col col-12">
          <Form.Item
            label={t("address.search")}
            
          >
            <AddressAutocomplete onSelected={onAddressSelected} name={[name, "searchAddress"]}/>
          </Form.Item>
        </div>}
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("address.streetName")}
            name={[name, "streetName"]}
            rules={ruleStringRequired}
          >
            <Input
              maxLength={60}
              disabled={disabled}
              onChange={handleInputChange}
            />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("address.houseNumber")}
            name={[name, "houseNumber"]}
            rules={ruleStringRequired}
          >
            <Input
              maxLength={60}
              disabled={disabled}
              onChange={handleInputChange}
            />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("address.city")}
            name={[name, "city"]}
            rules={[
              {
                required: required && cities?.length > 0,
              },
            ]}
          >
            <Input disabled={disabled} maxLength={60}/>
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            name={[name, "state"]}
            label={t("address.state")}
            rules={[
              {
                required: required && states?.length > 0,
              },
            ]}
          >
            <Input disabled={disabled} maxLength={60}/>
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("address.postCode")}
            name={[name, "postCode"]}
            rules={ruleStringRequired}
          >
            <Input
              type="tel"
              maxLength={7}
              min={0}
              onKeyDown={Utils.triggerTypeNumber}
              disabled={disabled}
              onChange={handleInputChange}
            />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("address.country")}
            name={[name, "country"]}
            required={required}
          >
            <Input disabled prefix={<Tag>{countryCode}</Tag>}/>
          </Form.Item>
        </div>
      </div>
    </>
  );
}

export default AddressForm;
