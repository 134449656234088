import { createSlice } from "@reduxjs/toolkit";

export const configSlice = createSlice({
  name: "config",
  initialState: {
    captchaEnabled: false,
    keySiteCaptcha: "",
    shopType: "",
    gaTrackingCode: "",
    gtmTrackingCode: "",
    sellerMail: "",
    supportMail: "",
    locales: [],
    paymentEnabled: false,
    technicalDrawingRequired: false,
    subfolderFrontendUrl: "",
    shopCountryCode: "",
    shopCountryVatRate: "",
    shopMaterial: "",
    minimumOrderValue: null,
    minimumOrderValueForFastDelivery: null,
    activeEconomy: true,
    activeFast: true,
    activeStandard: true,
  },
  reducers: {
    setData: (state, action) => {
      state.shopType = process.env.REACT_APP_SHOPTYPE ? process.env.REACT_APP_SHOPTYPE : action.payload.shopType;
      state.captchaEnabled = action.payload.captchaEnabled;
      state.keySiteCaptcha = action.payload.keySiteCaptcha;
      state.gaTrackingCode = process.env.REACT_APP_ENV == "production" ? action.payload.gaTrackingCode : "G-WCJLX4MR6R";
      state.gtmTrackingCode = action.payload.gtmTrackingCode;
      state.sellerMail = action.payload.sellerMail;
      state.supportMail = action.payload.supportMail || "support@gocad.de";
      state.locales = action.payload.locales;
      state.paymentEnabled = action.payload.paymentEnabled;
      state.technicalDrawingRequired = action.payload.technicalDrawingRequired;
      state.subfolderFrontendUrl = action.payload.subfolderFrontendUrl;
      state.shopCountryCode = action.payload.shopCountryCode;
      state.shopCountryVatRate = ((action.payload.shopCountryVatRate ?? 0 )* 100).toString();
      state.shopMaterial = action.payload.shopMaterial;
      state.minimumOrderValue = action.payload.minimumOrderValue;
      state.minimumOrderValueForFastDelivery = action.payload.minimumOrderValueForFastDelivery;
      state.activeEconomy = action.payload.activeEconomy;
      state.activeFast = action.payload.activeFast;
      state.activeStandard = action.payload.activeStandard;
    },
  },
});

export const configActions = configSlice.actions;
export const configSelector = (state: any) => state.config;
export default configSlice.reducer;
