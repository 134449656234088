import { AutoComplete, Input, Tag } from "antd";
import { useEffect, useState } from "react";

import { EnvironmentOutlined, SearchOutlined } from "@ant-design/icons";
import { GOOGLE_MAP_API_KEY } from "app/constants";
import { langSelector } from "app/redux/slides/locale.slide";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
interface DefaultProps {
  name?: any;
  onSelected: any;
}
function CountryAutocomplete(props: DefaultProps) {
  const { onSelected, name } = props;
  const { t } = useTranslation();
  const lang = useSelector(langSelector);
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: GOOGLE_MAP_API_KEY,
    language: lang,
    options: {
      types: ["country"],
      componentRestrictions: { country: ["de", "it", "cz", "hu"] },
    },
  });
  const [value, setValue] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [options, setOptions] = useState<{ value: any; label: any }[]>([]);
  const [placeDetails, setPlaceDetails] = useState<any>();

  function findAddressComponent(
    addressComponents: any,
    type: string,
    name: string = "long_name"
  ) {
    const component = addressComponents.find((comp: any) =>
      comp.types.includes(type)
    );
    return component ? component[name] : null;
  }
  const convertToCustomFormat = (data: any) => {
    const addressComponents = placeDetails.address_components;

    const customFormat = {
      country: findAddressComponent(addressComponents, "country"),
      countryCode: findAddressComponent(
        addressComponents,
        "country",
        "short_name"
      ),
      postCode: findAddressComponent(addressComponents, "postal_code"),
    };

    return customFormat;
  };
  useEffect(() => {
    if (placeDetails) {
      const data = convertToCustomFormat(placeDetails);
      setCountryCode(data.countryCode);
      if (onSelected) onSelected(data);
    }
  }, [placeDetails]);
  const onAddressSelected = (value: any) => {
    placesService?.getDetails(
      {
        placeId: value,
        language: lang,
      },
      (placeDetails: any) => {
        setValue(placeDetails.formatted_address);
        setPlaceDetails(placeDetails);
      }
    );
  };

  useEffect(() => {
    // fetch place details for the first element in placePredictions array
    if (placePredictions.length) {
      const options = placePredictions.map((item: any) => {
        return {
          value: item.place_id,
          label: (
            <>
              <EnvironmentOutlined /> {item.description}
            </>
          ),
        };
      });
      setOptions(options);
    }
  }, [placePredictions]);

  return (
    <>
      <AutoComplete
        value={value}
        options={options}
        onSelect={onAddressSelected}
        onSearch={(text) => {
          getPlacePredictions({ input: text, language: lang });
          setValue(text);
        }}
        children={
          <Input
            name={name}
            className="search-address"
            prefix={<Tag>{countryCode ?? ""}</Tag>}
            placeholder={
              t("country.search.placeHolder", {
                defaultValue: "",
              }) ?? ""
            }
          />
        }
      />
    </>
  );
}

export default CountryAutocomplete;
