import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Table,
  notification,
} from "antd";
import { BulkDeleteResponse, Material } from "app/models/Material";
import { MaterialShapeType } from "app/models/MaterialShapeType";
import { RawMaterial } from "app/models/RawMaterial";
import { ShopType } from "app/models/ShopType";
import { configSelector } from "app/redux/slides/config.slide";
import rawMaterialServices from "app/services/rawMaterial.service";
import { HTMLAttributes, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const { Option } = Select;

interface DefaultProps extends HTMLAttributes<any> {
  material?: Material;
}
function RawMaterialView(props: DefaultProps) {
  const { material } = props;
  const config = useSelector(configSelector);
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const [formAddRawMaterial] = Form.useForm();
  const [formRawMaterial] = Form.useForm();
  const [rawMaterials, setRawMaterials] = useState<RawMaterial[]>([]);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record: RawMaterial) =>
    `raw-material-${record.id}` === editingKey;
  const [selectedRawMaterial, setSelectedRawMaterial] = useState<RawMaterial[]>(
    []
  );
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState<any>(false);
  const [builkDeleteLoading, setBulkDeleteLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const getRawMaterials = async (materialId: number) => {
    if (config.shopType === ShopType.SHEET_METAL) return;
    try {
      const rs = await rawMaterialServices.list(materialId);
      setRawMaterials(rs);
    } catch (error) {}
  };

  useEffect(() => {
    if (material) {
      getRawMaterials(material.id);
    }
  }, [material]);

  const editRawMaterial = (record: RawMaterial) => {
    formRawMaterial.setFieldsValue(record);
    setEditingKey(`raw-material-${record.id}`);
  };

  const saveRawMaterial = async (id: number) => {
    try {
      const row = (await formRawMaterial.validateFields()) as RawMaterial;
      const newData = [...rawMaterials];
      const index = newData.findIndex((item) => id === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        await rawMaterialServices.update({
          ...item,
          ...row,
        });
        setRawMaterials(newData);
        setEditingKey("");
      }
      api.success({
        message: "Success!",
        description: "Raw material!",
        duration: 5,
        placement: "topRight",
      });
    } catch (error) {
      api.error({
        message: "Failed!",
        description: "Raw material!",
        duration: 20,
        placement: "topRight",
      });
    }
  };
  const deleteRawMaterial = async (id: number) => {
    try {
      await rawMaterialServices.deleteRawMaterial(id);
      const index = rawMaterials.findIndex((item) => id === item.id);
      rawMaterials.splice(index, 1);
      setRawMaterials([...rawMaterials]);
    } catch (error) {}
  };
  const cancelRawMaterial = () => {
    setEditingKey("");
  };
  const createRawMaterial = async (values: any) => {
    try {
      values.materialId = material?.id;
      const rs = await rawMaterialServices.create(values);
      setRawMaterials([rs, ...rawMaterials]);
      formAddRawMaterial.resetFields();
    } catch (error) {}
  };

  const rawMaterialColumns: any = [
    {
      title: t("type"),
      dataIndex: "shapeType",
      width: "8vw",
    },
    {
      title: t("diameter"),
      dataIndex: "diameter",
      editable: true,
    },
    // {
    //   title: t("width"),
    //   dataIndex: "width",
    //   editable: true,
    // },
    {
      title: t("height"),
      dataIndex: "height",
      editable: true,
    },
    {
      title: t("material.table.pricePerKilo"),
      dataIndex: "pricePerKilo",
      editable: true,
    },
    {
      title: t("action"),
      key: "operation",
      fixed: "right",
      width: 120,
      render: (_: any, record: RawMaterial) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              type="text"
              icon={<CheckOutlined />}
              onClick={saveRawMaterial.bind(null, record.id)}
            ></Button>
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={cancelRawMaterial}
            ></Button>
          </span>
        ) : (
          <span>
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={editRawMaterial.bind(null, record)}
            ></Button>
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={deleteRawMaterial.bind(null, record.id)}
            ></Button>
          </span>
        );
      },
    },
  ];

  const mergedRawMaterialColumns = rawMaterialColumns.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: RawMaterial) => ({
        record,
        inputType: "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const EditableCell: React.FC<any> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = <InputNumber type="number" />;

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item name={dataIndex} style={{ margin: 0 }}>
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const rowSelection = {
    onChange: async (
      selectedRowKeys: React.Key[],
      selectedRows: RawMaterial[]
    ) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRawMaterial(selectedRows);
    },
    getCheckboxProps: (record: RawMaterial) => ({
      name: record.id.toString(),
    }),
  };

  const handleBulkDelete = async () => {
    setBulkDeleteLoading(true);
    try {
      const ids = selectedRawMaterial.map((m: RawMaterial) => m.id);
      const rs: BulkDeleteResponse = await rawMaterialServices.bulkDeleteByIds(
        ids
      );

      let deleted: string[] = [];
      let cannotDelete: string[] = [];

      selectedRawMaterial.map((m: RawMaterial) => {
        if (rs.cannotDeleteIds.includes(m.id)) {
          cannotDelete.push(`${m.shapeType}${m.diameter ? '|' + m.diameter : ''}${m.height ? '|' + m.height : ''}`);
        } else {
          deleted.push(`${m.shapeType}${m.diameter ? '|' + m.diameter : ''}${m.height ? '|' + m.height : ''}`);
        }
      });

      if (deleted.length > 0) {
        api.success({
          message: t("deleted"),
          description: (
            <div>
              {t("deletedNotification")}
              <br />
              {deleted.map((m) => (
                <>
                  <b>{m}</b>
                  <br />
                </>
              ))}
            </div>
          ),
          placement: "topRight",
        });
      }

      if (cannotDelete.length > 0) {
        api.error({
          message: t("deletedFailed"),
          description: (
            <div>
              {t("popup.deleteRawMaterial.beingUsed")}
              <br />
              {cannotDelete.map((m) => (
                <>
                  <b>{m}</b>
                  <br />
                </>
              ))}
            </div>
          ),
          placement: "topRight",
        });
      }
    } catch (error) {}

    setSelectedRowKeys([]);
    setSelectedRawMaterial([]);
    setShowBulkDeleteModal(false);
    setBulkDeleteLoading(false);
    if (material) {
      getRawMaterials(material.id);
    }
  };

  return (
    <>
      {contextHolder}
      <Card className="mt-4 d-group">
        <p className="text-label group-title">{t("costReport.rawMaterial")}</p>
        <Form
          form={formAddRawMaterial}
          className="app-form"
          layout="vertical"
          onFinish={createRawMaterial}
        >
          <div className="row align-items-end">
            <div className="col">
              <Form.Item
                className="mb-0"
                name="shapeType"
                label={t("type")}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select>
                  {Object.values(MaterialShapeType)
                    .filter((value) => typeof value === "string")
                    .map((type) => (
                      <Option key={type} value={type}>
                        {type}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div className="col">
              <Form.Item className="mb-0" name="diameter" label={t("diameter")}>
                <InputNumber type="number" style={{ width: "100%" }} />
              </Form.Item>
            </div>
            {/* <div className="col">
              <Form.Item className="mb-0" name="width" label={t("width")}>
                <InputNumber />
              </Form.Item>
            </div> */}
            <div className="col">
              <Form.Item className="mb-0" name="height" label={t("height")}>
                <InputNumber type="number" style={{ width: "100%" }} />
              </Form.Item>
            </div>
            <div className="col">
              <Form.Item
                className="mb-0"
                name="pricePerKilo"
                label={t("material.table.pricePerKilo")}
              >
                <InputNumber
                  type="number"
                  style={{ width: "100%" }}
                  prefix={"€"}
                />
              </Form.Item>
            </div>
            {/* <div className="col">
              <Form.Item className="mb-0" name="length" label={t("length")}>
                <InputNumber />
              </Form.Item>
            </div> */}
            <div className="col col-auto">
              <Button type="primary" htmlType="submit">
                {t("addNew")}
              </Button>
            </div>
          </div>
        </Form>
        {selectedRawMaterial.length > 0 && (
          <Row className="mt-3">
            <Col>
              <Button
                onClick={setShowBulkDeleteModal.bind(null, true)}
                icon={<DeleteOutlined />}
                danger
              >
                {t("delete")}
              </Button>
            </Col>
          </Row>
        )}

        {rawMaterials && (
          <Form form={formRawMaterial} component={false}>
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Table
              className="mt-3"
              columns={mergedRawMaterialColumns}
              dataSource={rawMaterials}
              pagination={false}
              rowKey={(record) => `raw-material-${record.id}`}
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              rowSelection={{
                type: "checkbox",
                selectedRowKeys: selectedRowKeys,
                ...rowSelection,
              }}
            />
          </Form>
        )}
      </Card>
      <Modal
        confirmLoading={builkDeleteLoading}
        open={showBulkDeleteModal}
        title={t("delete")}
        onOk={handleBulkDelete}
        onCancel={setShowBulkDeleteModal.bind(null, false)}
      >
        <p>{t("popup.bulkDeleteRawMaterial.message")}</p>
      </Modal>
    </>
  );
}

export default RawMaterialView;
